




































import { activeCallStore, callsStore, teamsStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ServerCallBuzzParams, Meeting } from '@tada-team/tdproto-ts'
import CallMemberAvatar from './CallMemberAvatar.vue'
import { getChatType } from '@/utils'
import { meetings } from 'td-api'

import { TdButtonCall } from 'td-ui'

@Component({
  components: {
    TdButtonCall,
    CallMemberAvatar,
  },
})
export default class TheCallingWindow extends Vue {
  @Prop({
    type: ServerCallBuzzParams,
    required: true,
  }) readonly call!: ServerCallBuzzParams

  private meeting: Meeting | null = null

  async created (): Promise<void> {
    if (this.isMeetingCall) {
      const { getById } = meetings
      this.meeting = await getById(this.call.jid)
    }
  }

  get isMeetingCall () {
    return getChatType(this.call.jid) === 'meeting'
  }

  get entity () {
    if (this.isMeetingCall) return this.meeting
    return this.$store.getters.entity(this.call.jid)
  }

  get name (): string {
    return this.entity?.displayName ?? this.call.displayName
  }

  get nameCaption (): string {
    switch (this.call.chat.chatType) {
      case 'direct':
        return this.entity?.role ?? ''
      default: {
        const name = this.call.actor.displayName
        return this.$t('calls.isCalling', { name }).toString()
      }
    }
  }

  get nameClasses () {
    return this.entity?.chatType === 'direct' ? 'ellipsis-2-lines' : 'ellipsis'
  }

  mounted () {
    window.addEventListener('keydown', this.keydownHandler)
  }

  beforeDestroy () {
    window.removeEventListener('keydown', this.keydownHandler)
  }

  private keydownHandler (event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault()
      this.reject()
      return
    }

    if (event.key === 'Enter') {
      event.preventDefault()
      this.accept()
    }
  }

  private reject () {
    const teamId = teamsStore.state.currentTeamId
    if (!teamId) throw Error('Do not have an initialized current team')
    callsStore.actions.reject({
      teamId,
      chatId: this.call.jid,
    })
  }

  private accept () {
    activeCallStore.actions.answerCall({ jid: this.call.jid })
  }
}
