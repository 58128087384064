



















import { callsLogger } from '@/loggers'
import { IconData } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'

type CallIconData = {
  type: 'url',
  url: string
} | {
  type: 'letters',
  letters: string,
  color: string
}

@Component({
  name: 'CallMemberAvatar',
})
export default class CallMemberAvatar extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly icons!: IconData

  @Prop({
    type: Boolean,
    default: false,
  }) readonly talking!: boolean

  @Prop({
    type: String,
    default: '30%',
  }) readonly size!: string

  get iconData (): CallIconData {
    const { sm, lg, stub, letters, color } = this.icons
    if (letters && color) return { type: 'letters', letters, color }
    if (sm) return { type: 'url', url: sm.url }
    if (lg) return { type: 'url', url: lg.url }
    if (!stub) {
      callsLogger.warn(`Unable to create avatar from ${this.icons}`)
      // TODO: add Sentry
    }
    return { type: 'url', url: stub ?? '' }
  }

  get avatarStyle (): Partial<CSSStyleDeclaration> {
    if (this.iconData.type !== 'letters') return {}
    return {
      backgroundColor: this.iconData.color,
    }
  }

  get avatarFontSize () {
    return `calc(${this.size} / 3 )`
  }
}
